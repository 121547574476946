/**
 * Get the data group items form auto form fields.
 * @param {Array} fields
 * @returns {Array}
 */

const addToGroups = (list, field, depth = 0) => {
  const grouping = field?.props?.fieldUI?.grouping || field?.props?.grouping;
  const isError = !!field?.props?.error;
  const listItem = list.find(
    (item) => item.name === grouping || (grouping.length > depth && item.name === grouping[depth])
  );
  if (!listItem) {
    list.push({
      key: grouping[depth],
      name: grouping ? grouping[depth] : null,
      values: [field.key],
      isGroup: true,
      isError,
    });
  } else {
    if (isError) {
      listItem.isError = true;
    }
    if (grouping === null || grouping.length === depth + 1) {
      listItem.values.push(field.key);
    } else {
      addToGroups(listItem.values, field, depth + 1);
    }
  }
  return list;
};

const getDataGroupsFromFields = (fields) => {
  return fields.reduce((acc, field) => {
    const { key } = field;
    const grouping = field?.props?.fieldUI?.grouping || field?.props?.grouping;
    if (grouping && grouping.length !== 0) {
      addToGroups(acc, field, 0);
    } else {
      const lastElement = acc[acc.length - 1];
      if (lastElement) {
        if (!lastElement.isGroup) {
          lastElement.values.push(key);
        } else {
          acc.push({
            key,
            isGroup: false,
            values: [key],
          });
        }
      } else {
        acc.push({
          key,
          isGroup: false,
          values: [key],
        });
      }
    }
    return acc;
  }, []);
};

export default getDataGroupsFromFields;
