/**
 * A layout component to display a single select textfield.
 *
 * Defaults to an Autocomplete component with the prop isAutocomplete.
 *
 * For the isCreateable prop an Autocomplete component is automaically selected.
 */

import React from 'react';
import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';
import ListboxComponent from './ListBoxComponent';
import { DEFAULT_TEXT_FIELD_PROPS } from '../../consts';

const filter = createFilterOptions();

const useStyles = makeStyles()(() => ({
  listbox: {
    padding: 0,
    position: 'relative',
  },
}));

const SingleSelect = (props) => {
  const {
    className,
    disableClearable = true,
    disabled = false,
    emptyCreateable = false,
    error = false,
    formatCreateLabel,
    getOptionDisabled,
    getOptionLabel,
    getOptionSelected,
    helperText,
    InputProps,
    isAutocomplete = true,
    isCreateable = false,
    label = '',
    loading,
    onChange,
    onInputChange,
    onKeyDown,
    options = [],
    placeholder,
    renderOption,
    required = false,
    value,
  } = props;
  const { classes } = useStyles(props);

  return isAutocomplete || isCreateable ? (
    <Autocomplete
      className={className}
      classes={{
        listbox: classes.listbox,
      }}
      options={options}
      getOptionDisabled={getOptionDisabled}
      getOptionLabel={getOptionLabel ? getOptionLabel : (option) => (isString(option?.label) ? option?.label : option)}
      loading={loading}
      loadingText={i18n.t('placeholder.loading')}
      ListboxComponent={ListboxComponent}
      renderInput={(params) => (
        <TextField
          {...params}
          className={className}
          fullWidth
          helperText={helperText}
          InputProps={{ ...params.InputProps, ...InputProps }}
          InputLabelProps={placeholder ? { shrink: true } : {}}
          label={label}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          required={required}
          error={!!error}
          {...DEFAULT_TEXT_FIELD_PROPS}
        />
      )}
      renderOption={renderOption}
      onChange={onChange}
      onInputChange={onInputChange}
      value={typeof value !== 'undefined' ? value : null}
      isOptionEqualToValue={getOptionSelected ? getOptionSelected : (option, item) => option.id === item.id}
      disableClearable={disableClearable}
      disabled={disabled}
      noOptionsText={i18n.t('placeholder.noOptions')}
      closeText={i18n.t('tooltip.close')}
      openText={i18n.t('tooltip.open')}
      clearText={i18n.t('tooltip.clear')}
      filterOptions={(optionsToFilter, params) => {
        const filtered = filter(optionsToFilter, params);

        // Suggest the creation of a new value
        if (isCreateable && (params.inputValue !== '' || emptyCreateable)) {
          filtered.push({
            value: params.inputValue,
            label: formatCreateLabel ? formatCreateLabel(params.inputValue) : params.inputValue,
          });
        }

        return filtered;
      }}
      freeSolo={isCreateable}
    />
  ) : (
    <TextField
      className={className}
      disabled={disabled}
      error={!!error}
      fullWidth
      helperText={helperText}
      InputProps={InputProps}
      InputLabelProps={placeholder ? { shrink: true } : {}}
      label={label}
      onChange={(event) => {
        onChange(event, event.target.value);
      }}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      required={required}
      select
      SelectProps={{
        renderValue: (option) => {
          return getOptionLabel ? getOptionLabel(option) : option.label || option;
        },
      }}
      value={typeof value !== 'undefined' ? value : ''}
      {...DEFAULT_TEXT_FIELD_PROPS}
    >
      {options.map((option) => (
        <MenuItem key={option.id || option} value={option}>
          {getOptionLabel ? getOptionLabel(option) : option.label || option}
        </MenuItem>
      ))}
    </TextField>
  );
};

SingleSelect.propTypes = {
  className: PropTypes.string,
  disableClearable: PropTypes.bool,
  disabled: PropTypes.bool,
  emptyCreateable: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  formatCreateLabel: PropTypes.func,
  getOptionDisabled: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getOptionSelected: PropTypes.func,
  helperText: PropTypes.string,
  InputProps: PropTypes.object,
  isAutocomplete: PropTypes.bool,
  isCreateable: PropTypes.bool,
  label: PropTypes.string,
  loading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  renderOption: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number, PropTypes.object]),
};

export default SingleSelect;
