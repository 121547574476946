import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isNumber from 'lodash/isNumber';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';
import { i18n } from '@geomagic/i18n';
import { makeStyles, Trigger } from '@geomagic/core';
import Dialog from '@geomagic/nam-react-core/components/Dialog';
import Placeholder from '@components/Placeholder';
import getImagesFromAttachments from '@database/getImagesFromAttachments';
import { CLASSNAME_DOCUMENT } from '@graphql/consts';
import getCompressedHashedFile from '@image/getCompressedHashedFile';
import ImageCarousel from '@image/ImageCarousel';
import useShowPrompt from '@utils/useShowPrompt';
import getNewFileName from '@utils/getNewFileName';

import ModalActionBar from './ModalActionBar';

const MARGIN_TOP = 140;
const TRIGGER_SIZE = 54;

const useStyles = makeStyles()(({ palette, spacing }) => ({
  fileInput: {
    display: 'none',
  },
  triggerClose: {
    background: palette.action.active,
    color: palette.getContrastText(palette.action.active),
    width: TRIGGER_SIZE,
    height: TRIGGER_SIZE,
    position: 'absolute',
    right: spacing(),
    top: spacing(),
    zIndex: 1,
  },
}));

const IMAGE_TYPE_START = 'image/';
const checkDefaultCompression = (type) => type?.startsWith(IMAGE_TYPE_START);

const DCMImageUpload = (props) => {
  const { doc, documentTypeId, handleUpdateDraft, fileInputRef, isImageUpload, isSmallMobile, onBack, onConfirm } =
    props;
  const { classes } = useStyles(props);
  const [activeImageStep, setActiveImageStep] = useState(0);
  const [images, setImages] = useState([]);
  const maxSteps = images.length;
  const hasNoImages = maxSteps === 0;

  const showPrompt = useShowPrompt();

  /**
   *  EVENT HANDLER
   */

  const handleAdd = () => fileInputRef.current.click();

  const handleClickDelete = () => {
    showPrompt({
      content: i18n.t('dialog.deleteDocument.content'),
      title: i18n.t('dialog.deleteDocument.title'),
      onOk: handleDeleteImage,
    });
  };

  const handleDeleteImage = async () => {
    const imageToDelete = images[activeImageStep];

    const hash = imageToDelete?.id;
    const attachment = doc.getAttachment(hash);
    const entity = doc.getPatchedEntity();
    const { documents = [] } = entity;
    const newDocuments = documents.filter((documentDoc) => documentDoc?.hash !== hash);

    if (attachment) {
      await attachment.remove();
    }

    const newPatch = {
      op: 'replace',
      path: '/documents',
      value: newDocuments,
    };
    await handleUpdateDraft(newPatch);

    const newImages = await getImagesFromAttachments(newDocuments, doc);

    setActiveImageStep((prevImageStep) => (prevImageStep === 0 ? 0 : prevImageStep - 1));
    setImages(newImages);
  };

  const handleStepChange = (step) => {
    if (isNumber(step)) {
      setActiveImageStep(step);
    } else {
      setActiveImageStep(null);
    }
  };

  const handleFileInputChange = async (event) => {
    const files = event.target.files;
    const errors = [];
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      const compression = checkDefaultCompression(file.type);

      try {
        if (compression) {
          const { hash, file: compressedFile } = await getCompressedHashedFile(file);

          const entity = doc.getPatchedEntity();
          const { documents = [] } = entity;
          const fileName = getNewFileName(file, documents);

          const newDocuments = [
            ...documents,
            {
              className: CLASSNAME_DOCUMENT,
              displayName: fileName,
              properties: { contentType: file.type, hash },
              entityType: {
                documentTypeId,
              },
              hash,
            },
          ];

          await doc.putAttachment({ id: hash, data: compressedFile, type: file.type });
          const newPatch = {
            op: 'replace',
            path: '/documents',
            value: newDocuments,
          };
          await handleUpdateDraft(newPatch);

          const newImages = await getImagesFromAttachments(newDocuments, doc);

          setImages(newImages);
        }
      } catch (error) {
        errors.push(error);
      }
    }
  };

  /**
   * COMPONENTS
   */

  const ActionsComponent = (
    <Box sx={{ display: 'block', flex: 1, flexDirection: 'column' }}>
      <ModalActionBar onAdd={handleAdd} onBack={onBack} onConfirm={onConfirm} />
    </Box>
  );

  const ContentComponent = hasNoImages ? (
    <Box
      sx={{ alignItems: 'center', display: 'flex', flex: 1, height: '100%', justifyContent: 'center', width: '100%' }}
    >
      <Placeholder
        icon={<ImageIcon />}
        iconSize={80}
        title={i18n.t('dispatch.placeholder.noPhotos.title')}
        subtitle={i18n.t('dispatch.placeholder.noPhotos.subtitle')}
      />
    </Box>
  ) : (
    <>
      <Trigger
        className={classes.triggerClose}
        color="inherit"
        icon={<DeleteIcon fontSize="large" />}
        onClick={handleClickDelete}
        variant="icon"
      />
      <ImageCarousel activeStep={activeImageStep} images={images} onChangeStep={handleStepChange} />
    </>
  );

  return (
    <>
      <Dialog
        actions={ActionsComponent}
        content={ContentComponent}
        dialogContentProps={{
          sx: {
            flex: 1,
            padding: '0 !important',
          },
        }}
        PaperProps={{
          sx: {
            boxShadow: 'none',
            height: '100%',
            ...(!isSmallMobile && {
              height: `calc(100% - ${MARGIN_TOP}px)`,
              marginTop: `${MARGIN_TOP}px`,
            }),
          },
        }}
        fullScreen={isSmallMobile}
        fullWidth
        maxWidth={false}
        open={isImageUpload}
        scrollableContent={false}
      />
      <input
        accept="image/*"
        capture="environment"
        className={classes.fileInput}
        onChange={handleFileInputChange}
        ref={fileInputRef}
        type="file"
      />
    </>
  );
};

DCMImageUpload.propTypes = {
  doc: PropTypes.object,
  documentTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleUpdateDraft: PropTypes.func.isRequired,
  fileInputRef: PropTypes.object.isRequired,
  isImageUpload: PropTypes.bool,
  isSmallMobile: PropTypes.bool,
  onBack: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default DCMImageUpload;
