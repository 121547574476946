import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import ReadOnlyField from '@components/ReadOnlyField';
import getAttributType from '@database/getAttributType';
import { makeStyles, Trigger } from '@geomagic/core';
import { AutoForm, submitForm } from '@geomagic/forms';
import { i18n } from '@geomagic/i18n';
import {
  getAutoFormPropsByAttributeType,
  getFormattedAttributeValue,
  getRawAttributeValue,
} from '@geomagic/nam-react-core/utils';

import findValueFromParent from '../utils/findValueFromParent';

const useStyles = makeStyles()(({ breakpoints, spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    margin: spacing(1, 0.5),
    minWidth: 120,
    [breakpoints.down('sm')]: {
      flex: 'initial',
      marginTop: spacing(1.5),
      width: '100%',
    },
    [breakpoints.down('md')]: {
      marginBottom: spacing(1),
    },
  },
  autoForm: {
    flex: 1,
    maxWidth: 300,
  },
  trigger: {
    marginLeft: spacing(),
  },
}));

const FORM_ID = 'fieldDataForm';
const FORM_FIELD_ID = 'fieldData';

const getAutoFormProps = (pseudoAttributeType) => {
  const requiredList = [];
  const { fieldSchema, fieldUI } = getAutoFormPropsByAttributeType(pseudoAttributeType);

  if (pseudoAttributeType.mandatory && !pseudoAttributeType.readOnly) {
    requiredList.push(FORM_FIELD_ID);
  }

  return {
    schema: {
      type: 'object',
      properties: {
        [FORM_FIELD_ID]: fieldSchema,
      },
      required: requiredList,
    },
    ui: {
      [FORM_FIELD_ID]: fieldUI,
    },
  };
};

const FormElementFieldData = (props) => {
  const { context, data, doc, path } = props;
  const assignment = doc?.getPatchedEntity() || {};
  const { forms } = assignment;

  const { classes } = useStyles(props);

  const {
    assignmentAttribTypeId,
    assignmentTypeId,
    dataType,
    funclocAttribTypeId,
    funclocEditable,
    funclocTypeId,
    id,
    mandatory,
    name,
    unit,
    value,
    options,
    oldValue,
  } = data;

  const { activeEditField, entityClasses, isReadOnly, onUpdate, setActiveEditField } = context;

  const attributType =
    getAttributType({ assignmentAttribTypeId, assignmentTypeId, funclocAttribTypeId, funclocTypeId }, entityClasses) ||
    {};

  const { allowedValues = [], readOnly = true } = attributType;

  const pseudoAttributeType = { allowedValues, dataType, mandatory, name, options, readOnly, unit };

  const [autoFormProps] = useState(() => getAutoFormProps(pseudoAttributeType));
  const isEditMode = !!activeEditField;
  const isEditing = activeEditField === id;

  const formattedValue =
    value !== null
      ? getFormattedAttributeValue(pseudoAttributeType, value)
      : funclocAttribTypeId && funclocTypeId
      ? findValueFromParent(id, funclocTypeId, funclocAttribTypeId, forms, pseudoAttributeType)
      : '';
  const oldFormattedValue =
    oldValue !== null ? (
      <>
        <s>{getFormattedAttributeValue(pseudoAttributeType, oldValue)}</s>{' '}
      </>
    ) : (
      ''
    );

  const handleChange = (values) => {
    const { fieldData } = values;
    const areValuesEqual =
      getRawAttributeValue(pseudoAttributeType, oldValue || value) ===
      getRawAttributeValue(pseudoAttributeType, fieldData);
    const newOldValue = areValuesEqual ? null : !oldValue ? value : oldValue;
    onUpdate({ oldValue: newOldValue, value: fieldData }, path, data);
    setActiveEditField(null);
  };

  return (
    <div className={classes.root}>
      {isEditing ? (
        <div className={classes.autoForm}>
          <AutoForm id={FORM_ID} defaultValues={{ fieldData: value }} onSubmit={handleChange} {...autoFormProps} />
        </div>
      ) : (
        <ReadOnlyField
          formControlProps={{ fullWidth: false }}
          title={name}
          value={
            formattedValue ? (
              <>
                {oldFormattedValue}
                {oldFormattedValue ? <b>{formattedValue}</b> : formattedValue}
              </>
            ) : (
              ''
            )
          }
        />
      )}
      {!isReadOnly && funclocEditable && !readOnly ? (
        isEditing ? (
          <>
            <Trigger
              className={classes.trigger}
              icon={<CheckIcon />}
              onClick={(event) => submitForm(FORM_ID)}
              tooltip={i18n.t('tooltip.acceptChange')}
            />
            <Trigger
              icon={<ClearIcon />}
              onClick={(event) => setActiveEditField(null)}
              tooltip={i18n.t('tooltip.cancelChange')}
            />
          </>
        ) : (
          <>
            <Trigger
              className={classes.trigger}
              disabled={isEditMode}
              icon={<EditIcon />}
              onClick={(event) => setActiveEditField(id)}
              tooltip={i18n.t('tooltip.edit')}
            />
          </>
        )
      ) : null}
    </div>
  );
};

FormElementFieldData.propTypes = {
  context: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  doc: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
};

export default FormElementFieldData;
