import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@geomagic/core';
import { AutoForm, submitForm } from '@geomagic/forms';
import { getRawAttributeValue, getReference } from '@geomagic/geonam';
import Dialog from '@geomagic/nam-react-core/components/Dialog';
import {
  formValidationHandler,
  getAutoFormPropsByAttributeType,
  getFormattedAttributeValue,
} from '@geomagic/nam-react-core/utils';
import EnumSelect from './EnumSelect';
import ModalActionBar from './ModalActionBar';

const MARGIN_TOP = 140;

const useStyles = makeStyles()(({ breakpoints, palette }) => ({
  root: {},
  dialogActions: {
    display: 'block',
  },
  dialogContent: {
    flex: 1,
  },
  dialogPaper: {
    background: palette.background.default,
    boxShadow: 'none',
    height: '100%',
    [breakpoints.up('sm')]: {
      height: `calc(100% - ${MARGIN_TOP}px)`,
      marginTop: MARGIN_TOP,
    },
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  formField: {
    background: palette.background.default,
  },
}));

const AUTO_FORM_ID = 'FillAttributForm';
const ENUM_SELECT_MAX_SIZE = 16;

const createFormSchemaAndUI = (attributeTypes, entity) => {
  const requiredList = [];
  const properties = {};

  const ui = {};

  attributeTypes.forEach((attributeType, index) => {
    if (!attributeType.readOnly || entity) {
      const { fieldSchema, fieldUI } = getAutoFormPropsByAttributeType(attributeType, entity, false);

      if (fieldSchema) {
        properties[index] = fieldSchema;
      }

      if (
        attributeType.allowedValues &&
        attributeType.allowedValues.length > 0 &&
        attributeType.allowedValues.length <= ENUM_SELECT_MAX_SIZE
      ) {
        ui[index] = (fieldUIProps) => {
          const { error, label, updateField, value } = fieldUIProps;

          return (
            <EnumSelect
              error={error}
              getOptionLabel={(item) => getFormattedAttributeValue(attributeType, item.value)}
              label={label}
              onChange={(event, newValue) => {
                updateField(newValue);
              }}
              options={attributeType.allowedValues}
              required={attributeType.mandatory}
              value={value}
            />
          );
        };
      } else {
        ui[index] = fieldUI;
      }

      if (attributeType.mandatory && !attributeType.readOnly) {
        requiredList.push(index.toString());
      }
    }
  });

  return {
    schema: {
      type: 'object',
      required: requiredList,
      properties,
    },
    ui,
  };
};

const getUpdatedAttributeValues = (attributeTypes, values) => {
  return attributeTypes.map((attributeType, index) => {
    const typedValue = values[String(index)];

    return {
      attributeType: getReference(attributeType),
      value: getRawAttributeValue(attributeType, typedValue),
    };
  });
};

const getMergedAttributeValues = (attributeTypes, attributeValues) => {
  return attributeTypes.map((attributeType, index) => {
    const value = attributeValues.find((attributeValue) => attributeValue.attributeType.id === attributeType.id);

    return value
      ? value
      : {
          attributeType: getReference(attributeType),
          value: getRawAttributeValue(attributeType, undefined),
        };
  });
};

const DCMAttributes = (props) => {
  const {
    allAttributeTypes = [],
    dispatchAttribTypes = [],
    doc,
    isAttributesFill,
    isSmallMobile,
    onBack,
    onConfirm,
  } = props;
  const { classes } = useStyles(props);

  const dispatch = doc?.getPatchedEntity();

  const schemaUI = useMemo(() => {
    return createFormSchemaAndUI(dispatchAttribTypes, dispatch);
  }, [dispatch, dispatchAttribTypes]);
  const { schema, ui } = schemaUI;

  /**
   *  EVENT HANDLER
   */

  const handleSubmit = (values, formContext) => {
    const newAttribValues = getUpdatedAttributeValues(dispatchAttribTypes, values);
    const mergedValues = getMergedAttributeValues(allAttributeTypes, newAttribValues);

    onConfirm(mergedValues);
  };

  return (
    <>
      <AutoForm id={AUTO_FORM_ID} schema={schema} ui={ui} onSubmit={handleSubmit} onError={formValidationHandler}>
        {(fields, formContext) => {
          return (
            <Dialog
              classes={{
                dialogActions: classes.dialogActions,
                dialogContent: classes.dialogContent,
                paper: classes.dialogPaper,
              }}
              fullScreen={isSmallMobile}
              fullWidth
              maxWidth={false}
              open={isAttributesFill}
              actions={<ModalActionBar onBack={onBack} onConfirm={() => submitForm(AUTO_FORM_ID)} />}
              content={<div className={classes.flexContainer}>{fields}</div>}
            />
          );
        }}
      </AutoForm>
    </>
  );
};

DCMAttributes.propTypes = {
  allAttributeTypes: PropTypes.array,
  dispatchAttribTypes: PropTypes.array,
  doc: PropTypes.object,
  isAttributesFill: PropTypes.bool,
  isSmallMobile: PropTypes.bool,
  onBack: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default DCMAttributes;
