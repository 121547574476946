import React from 'react';
import { DEFAULT_TEXT_FIELD_PROPS } from '@consts';
import dayjs from 'dayjs';

import SingleSelect from '../components/SingleSelect';

import getNumberFormatObject from './getNumberFormatObject';
import getStringifiedAttributeValue from './getStringifiedAttributeValue';
import getTypedAttributeValue from './getTypedAttributeValue';
import DateTimeInput from './DateTimeInput';
import getDateFormatObject from './getDateFormatObject';

/**
 * Get Autoform schema and ui by attribute type
 * @param {object} attributeType - GeoNAM attribute type
 * @param {object} entity - GeoNAM entity
 * @returns {object}
 */

const getAutoFormPropsByAttributeType = (attributeType, entity, isReadOnly, isMobile) => {
  let enumList;
  let fieldUI = {};
  let type;
  let schemaProps = {};

  const { options } = attributeType;

  switch (attributeType.dataType) {
    case 'STRING':
    case 'LINK':
    case 'EMAIL':
      const { rows, regex, minLength, maxLength } = options;
      type = 'string';
      if (rows && rows > 1) {
        fieldUI.rows = rows;
        fieldUI.multiline = true;
      }
      fieldUI = {
        ...fieldUI,
        ...DEFAULT_TEXT_FIELD_PROPS,
      };
      schemaProps = {
        ...(regex && { pattern: regex }),
        ...(minLength && { minLength }),
        ...(maxLength && { maxLength }),
      };
      break;
    case 'INT':
    case 'FLOAT':
    case 'FRACTION':
    case 'CURRENCY':
      if (!enumList) {
        const { minValue, maxValue } = options;
        fieldUI = {
          ...fieldUI,
          ...getNumberFormatObject(attributeType),
          isFormatted: true,
          ...DEFAULT_TEXT_FIELD_PROPS,
        };
        schemaProps = {
          ...(minValue && { minimum: minValue }),
          ...(maxValue && { maximum: maxValue }),
        };
      }
      type = 'number';
      break;
    case 'BOOLEAN':
      type = 'boolean';
      break;
    case 'DATE':
    case 'TIMESTAMP':
      const { minRelDays, maxRelDays } = options;
      const createdOn = entity?.auditInfo?.createdOn;
      fieldUI = {
        attributeType,
        CustomFormField: DateTimeInput,
        dateFormatObject: getDateFormatObject(attributeType, entity),
        isMobile,
      };
      schemaProps = {
        ...(minRelDays && {
          minimum: createdOn ? dayjs(createdOn).add(minRelDays, 'd').valueOf() : dayjs().add(minRelDays, 'd').valueOf(),
        }),
        ...(maxRelDays && {
          maximum: createdOn ? dayjs(createdOn).add(maxRelDays, 'd').valueOf() : dayjs().add(maxRelDays, 'd').valueOf(),
        }),
      };
      type = ['number', 'null'];
      break;
    default:
      break;
  }

  if (attributeType.allowedValues && attributeType.allowedValues.length > 0) {
    enumList = attributeType.allowedValues.map((allowedValue) =>
      getTypedAttributeValue(attributeType, allowedValue.value)
    );
    fieldUI = (fieldUIProps) => {
      const { updateField, value, label, error } = fieldUIProps;

      return (
        <SingleSelect
          disableClearable={false}
          disabled={attributeType.readOnly || isReadOnly}
          error={error}
          getOptionLabel={(item) => getStringifiedAttributeValue(attributeType, item)}
          getOptionSelected={(option, item) => {
            return option === item;
          }}
          isAutocomplete={type === 'number' || enumList.length < 5 ? false : undefined}
          label={label}
          onChange={(event, newValue) => {
            updateField(newValue);
          }}
          options={enumList}
          renderOption={(props, option) => <li {...props}>{getStringifiedAttributeValue(attributeType, option)}</li>}
          required={attributeType.mandatory}
          value={value}
        />
      );
    };
  }

  if (attributeType.readOnly || isReadOnly) {
    fieldUI.disabled = true;
  }

  return {
    fieldSchema: {
      type,
      title: attributeType.name,
      enum: enumList,
      ...schemaProps,
    },
    fieldUI: {
      ...fieldUI,
      identifier: attributeType.id,
      grouping: attributeType.grouping,
    },
  };
};

export default getAutoFormPropsByAttributeType;
